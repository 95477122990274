import type { ThemeSettings } from 'types';

import { DEFAULT_THEME_SETTINGS } from './constants';

export function useThemeSettings(): ThemeSettings {
    return {
        accentColor: DEFAULT_THEME_SETTINGS.accent_color,
        headerBackgroundColor: DEFAULT_THEME_SETTINGS.header_background_color,
        headerLinkColor: DEFAULT_THEME_SETTINGS.header_link_color,
        showDate: DEFAULT_THEME_SETTINGS.show_date,
        showSubtitle: DEFAULT_THEME_SETTINGS.show_subtitle,
    };
}
