import type { ThemeSettingsApiResponse } from 'types';

// Lena Theme Settings from the customer's newsroom as of 22.03.2023 with updated primary colour
export const DEFAULT_THEME_SETTINGS: ThemeSettingsApiResponse = {
    accent_color: '#0072FB',
    header_background_color: '#0072FB',
    header_link_color: '#FFFFFF',
    show_date: true,
    show_subtitle: false,
};
